import React from 'react';
import { Link } from 'react-router-dom';
import Pack from './Pack';
import ProductSlider from './ProductSlider';

export default class System extends React.Component {

    render() {
        return (
            <div>
    
                <div className="header-container">
                    <div className="header-content">
                        <h2>
                            <Link to={`/${this.props.system.slug}/packs`}>
                                <img src={`/images/logos/${this.props.system.slug}-logo.png`} className="system-logo"/> {this.props.system.name} Stickers
                            </Link>
                        </h2>
                        <blockquote>
                            <p>
                                Search {this.props.system.name} stickers by <Link to={`/${this.props.system.slug}/tags`}>tags</Link>.
                                Add tags and vote for the existing ones. Browse <Link to={`/${this.props.system.slug}/packs`}>sticker packs</Link>.
                            </p>
                            <form className="form-inline">
                                <div className="form-group">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder={`Search ${this.props.system.name} stickers...`} />
                                        <span className="input-group-btn">
                                            <button type="button" className="btn btn-default">Search</button>
                                        </span>
                                    </div>
                                </div>
                            </form>
                        </blockquote>
                    </div>
                    <div className="product-container">
                        <div>
                            
                            <ProductSlider
                                keywords="Sticker"
                                postfix={this.props.system.slug}
                                sdks={this.props.sdks}
                                repo={this.props.repo}
                            />

                        </div>
                    </div>
                </div>

                <div className="clearfix">
                    <div>
                        {this.props.system.packs.map(pack =>
                            <Pack pack={pack} key={pack.id} />
                        )}
                    </div>
                    <div className="pack">
                        <Link to={`/${this.props.system.slug}/packs`} className="btn btn-info pull-left see-all-packs" role="button">See all</Link>
                    </div>
                </div>

            </div>

        );
    }

}
