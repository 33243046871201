import React from 'react';
import { Link } from 'react-router-dom';

const AuthorItem = (props) => (
    <div className="author">

        <div className="author-logo">
            <Link to={`/authors/${props.author.slug}`}><img src={props.author.logo} /></Link>
        </div>
        <div><Link to={`/authors/${props.author.slug}`}><strong>{props.author.name}</strong></Link></div>

    </div>
);

export default AuthorItem;
