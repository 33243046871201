import React from 'react';
import System from './System';
import PageMeta from './PageMeta';

export default class HomePage extends React.Component {

    constructor(props) {
        super(props);
        if (props.data) {
            this.state = { ...props.data };
        } else if (window.__DATA__) {
            this.state = window.__DATA__;
            delete window.__DATA__;
        } else {
            this.state = {
                systems: []
            };
        }
    }

    static loadData(props) {
        return Promise.all(props.systems.map(system => (
            props.repo.fetchPacks({
                count: 20,
                systemId: system.id
            })
            .then(packs => ({
                ...system,
                packs
            }))
        )))
        .then(systems => ({
            systems,
            loaded: true
        }));
    }

    fetch() {
        HomePage.loadData(this.props)
            .then(data => this.setState(data));
    }

    componentDidMount() {
        if (!this.state.loaded) this.fetch();
    }

    render() {
        return (
            <div>
                
                <PageMeta
                    title="Sticker Search"
                    description="Search Facebook and Telegram stickers by tags. Add tags and vote for the existing ones."
                    metaImage="/images/logo.png"
                />

                <h1><img src="/images/logo.png" className="system-logo"/> Sticker Search</h1>

                <div>
                    {this.state.systems.map(system =>
                        <System system={system} repo={this.props.repo} key={system.id} />
                    )}
                </div>
                
            </div>
        );
    }

}
