import React from 'react';
import { Link } from 'react-router-dom';
import SearchBox from './SearchBox';
import PageMeta from './PageMeta';
import ProductSlider from './ProductSlider';

export default class TagsPage extends React.PureComponent {

    constructor(props) {
        super(props);
        if (props.data) {
            this.state = { ...props.data };
        } else if (window.__DATA__) {
            this.state = window.__DATA__;
            delete window.__DATA__;
        } else {
            this.state = {
                tags: []
            };
        }
    }

    componentDidMount() {
        if (!this.state.loaded) this.fetch();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.system !== this.props.system) {
            this.fetch();
        }
    }

    static loadData(props) {
        return props.repo.fetchTags({
            systemId: props.system.id
        })
        .then(tags => ({
            tags,
            loaded: true
        }));
    }

    fetch() {
        TagsPage.loadData(this.props)
            .then(data => this.setState(data));
    }

    render() {
        const system = this.props.system;

        return (
            <div>
                <PageMeta
                    title={system.name + ' Sticker Tags | Sticker Search'}
                    description={`All tags for ${system.name} stickers.`}
                />

                <div className="header-container">
                    <div className="header-content">
                        <div></div>
                            <SearchBox system={system} />
                        <h1>
                            <img src={`/images/logos/${system.slug}-logo.png`} className="system-logo"/>{' '}
                            {system.name} Sticker Tags
                        </h1>
                        <div className="social-share-container">
                            <a className="btn btn-primary btn-sm" role="button" target="_blank"
                                    onClick={() => this.props.share({
                                        url: `https://stickersearch.net/${system.slug}/tags`,
                                        text: system.name + ' Sticker Tags',
                                        hashtags: [ system.name + 'Stickers', 'StickerTags', 'Stickers' ],
                                        isOpen: true
                                    })}>
                                <i className="fa fa-share-alt"></i> Share
                            </a>
                        </div>
                    </div>
                    <div className="product-container">
                        <div>
                            <ProductSlider
                                keywords="Stickers"
                                sdks={this.props.sdks}
                                repo={this.props.repo}
                            />
                        </div>
                    </div>
                </div>

                <div className="tag-list clearfix">
                    {this.state.tags.map(tag =>
                        <Link to={`/${system.slug}/tags/${tag.slug}`} className="btn btn-default btn-sm" key={tag.id}>{tag.name}</Link>
                    )}
                </div>

            </div>
        );
    }

}
