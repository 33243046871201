import React from 'react';
import { Link } from 'react-router-dom';
import Sticker from './Sticker';
import SearchBox from './SearchBox';
import { capitalize } from '../util';
import BaseStickersPage from './BaseStickersPage';
import PageMeta from './PageMeta';
import ProductSlider from './ProductSlider';

export default class PackPage extends BaseStickersPage {

    constructor(props) {
        super(props);
        if (props.data) {
            this.state = { ...props.data };
        } else if (window.__DATA__) {
            this.state = window.__DATA__;
            delete window.__DATA__;
        } else {
            this.state = {
                pack: null,
                stickers: []
            }
        }

        this.handleAddPackClick = this.handleAddPackClick.bind(this);
    }

    componentDidMount() {
        if (!this.state.loaded) this.fetch();
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.system !== this.props.system || prevProps.slug !== this.props.slug || prevProps.user !== this.props.user) {
            this.fetch();
        }
    }

    static loadData(props) {
        return Promise.all([
            props.repo.fetchPacks({
                slug: props.slug,
                systemId: props.system.id
            }),
            props.repo.fetchStickers({
                packSlug: props.slug,
                systemId: props.system.id
            })
        ]).then(([packs, stickers]) => {
            return {
                pack: packs[0],
                stickers,
                loaded: true
            };
        });
    }

    fetch() {
        PackPage.loadData(this.props)
            .then(data => this.setState(data));
    }

    handleShare() {
        if (!this.state.pack) return;
        
        let authorsPart = this.state.pack.authors
            .filter(author => author.data.twitterHandle)
            .map(author => ' @' + author.data.twitterHandle);

        this.props.share({
            url: `https://stickersearch.net/${this.props.system.slug}/packs/${this.state.pack.slug}`,
            text: `${this.state.pack.name} #${this.props.system.name}Stickers` + authorsPart + '.',
            hashtags: [
                'Stickers',
                capitalize(this.state.pack.name.replace(/\W/g, ' ')).replace(/ /g, '')
            ],
            isOpen: true
        });
    }

    handleAddPackClick() {
        // Track adding packs:
        if (this.state.pack && this.state.pack.link) {
            this.props.sdks.ga.then(ga => {
                ga('send', 'event', {
                    eventCategory: 'Pack',
                    eventAction: 'add',
                    eventLabel: this.props.system.slug + '/' + this.state.pack.slug
                });
            });
        }
    }

    render() {
        if (!this.state.pack) return null;

        const system = this.props.system;
        const { pack, stickers } = this.state;

        return (

            <div>
                <PageMeta
                    title={`${pack.name} ${system.name} Stickers | Sticker Search`}
                    description={
                        (pack.desc ? pack.desc + ' ' : '') +
                        `${system.name} sticker pack "${pack.name}". See all stickers from this pack.`}
                    stickersForMetaImage={stickers}
                />

                <div className="header-container">
                    <div className="header-content">
                        <div>
                            <SearchBox system={system} />
                        </div>
                        <h1>
                            <img src={`/images/logos/${system.slug}-logo.png`} className="system-logo"/>
                            <span> {pack.name} </span>
                            <small>{system.name} Sticker Pack</small>
                        </h1>
                        <div className="social-share-container">
                            {this.state.pack.link &&
                                <a className="btn btn-primary btn-sm add-pack" role="button" target="_blank"
                                        onClick={this.handleAddPackClick}
                                        href={this.state.pack.link}>
                                    <i className="fa fa-plus-circle"></i> Add stickers
                                </a>
                            }{' '}
                            <a className="btn btn-primary btn-sm" role="button" target="_blank"
                                    onClick={this.handleShare.bind(this)}>
                                <i className="fa fa-share-alt"></i> Share
                            </a>
                        </div>

                        <blockquote>
                            <p>{pack.desc}</p>
                            <footer>
                                <ul className="authors">
                                    {pack.authors && pack.authors.length > 0
                                        ? pack.authors.map(author =>
                                            <li key={author.slug}>
                                                <Link to={`/authors/${author.slug}`}>{author.name}</Link>
                                            </li>)
                                        : pack.author && <li>{pack.author}</li>
                                    }
                                </ul>
                            </footer>
                        </blockquote>

                    </div>
                    <div className="product-container">
                        <div>
                            {this.state.pack &&
                                <ProductSlider
                                    keywords={this.state.pack.name}
                                    sdks={this.props.sdks}
                                    repo={this.props.repo}
                                />
                            }
                        </div>
                    </div>
                </div>

                <div className="clearfix">
                    {stickers.map(sticker =>
                        <Sticker
                            key={sticker.id}
                            sticker={sticker}
                            system={system}
                            repo={this.props.repo}
                            login={this.props.login}
                            sdks={this.props.sdks}
                            updateStickerTag={this.updateStickerTag} />
                    )}
                </div>

            </div>

        );
    }

}
