import React from 'react';
import { Link } from 'react-router-dom';

export default class Sticker extends React.PureComponent {

    constructor(props) {
        super(props);
        
        this.state = {
            backgroundPosition: `0px 0px`,
            backgroundImage: `url(${this.props.sticker.imageUrl})`,
            backgroundSize: `contain`
        };
        
        this.toAnimate = false;
        this.intervalId = null;

        this.handleMouseOver = this.handleMouseOver.bind(this);
        this.handleMouseOut = this.handleMouseOut.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.sticker.imageUrl !== prevProps.sticker.imageUrl) {
            this.setState({ backgroundImage: `url(${this.props.sticker.imageUrl})` });
        }
    }

    handleMouseOver() {
        if (this.props.sticker.animation) this.animate(true);
    }

    handleMouseOut() {
        if (this.props.sticker.animation) this.animate(false);
    }

    animate(toAnimate) {
        this.toAnimate = toAnimate;

        if (this.toAnimate) {
            if (!this.animationSpriteImageEl) {
                // Preload image with animation sprites,
                // start animating only when image loaded.
                
                this.animationSpriteImageEl = new Image();
                this.animationSpriteImageEl.addEventListener('load', () => {
                    this.startAnimation();
                }, false);
                this.animationSpriteImageEl.src = this.props.sticker.animation.spriteUrl;
            } else {
                this.startAnimation();
            }
        }
    }

    startAnimation() {
        if (this.intervalId) return;    // Current animation not finished, return.

        const sticker = this.props.sticker;
        const animation = sticker.animation;

        const bgWidth = animation.framesPerRow * sticker.width;
        const bgHeight = animation.framesPerCol * sticker.height;
        this.setState({
            backgroundImage: `url(${animation.spriteUrl})`,
            backgroundSize: `${bgWidth}px ${bgHeight}px`
        });
        
        let frameIndex = 0;

        this.intervalId = setInterval(() => {
            frameIndex = (frameIndex + 1) % animation.frameCount;
            if (!this.toAnimate && frameIndex === 0) {
                clearInterval(this.intervalId);
                this.intervalId = null;

                this.setState({
                    backgroundPosition: `0px 0px`,
                    backgroundImage: `url(${sticker.imageUrl})`,
                    backgroundSize: `contain`
                });
            }
            // TODO This should probably go to else branch:
            const x = frameIndex % animation.framesPerRow * sticker.width;
            const y = Math.floor(frameIndex / animation.framesPerRow) * sticker.height;
            this.setState({
                backgroundPosition: `-${x}px -${y}px`
            });
        }, animation.frameRate);
    }

    render() {
        const sticker = this.props.sticker;

        return (
            <Link className="image" to={`/${sticker.pack.system.slug}/packs/${sticker.pack.slug}`}
                style={{
                    backgroundRepeat: 'no-repeat',
                    height: sticker.height,
                    width: sticker.width,
                    backgroundImage: this.state.backgroundImage,
                    backgroundSize: this.state.backgroundSize,
                    backgroundPosition: this.state.backgroundPosition
                }}
                onMouseOver={this.handleMouseOver}
                onMouseOut={this.handleMouseOut}
            ></Link>
        );
    }

}
