import React from 'react';
import TextField from './TextField';
import Modal from './Modal';

export default class PasswordModal extends React.Component {

    constructor(props) {
        super(props);

        this.currentPasswordRef = React.createRef();

        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.state = {
            currentPassword: '',
            newPassword1: '',
            newPassword2: '',
            fieldErrors: {},
            focusCurrentPassword: false
        };
    }

    handleClose() {
        this.props.change({ isOpen: false });
        this.setState({
            currentPassword: '',
            newPassword1: '',
            newPassword2: '',
            fieldErrors: {},
            focusCurrentPassword: false
        });
    }

    handleShow() {
        this.setState({
            focusCurrentPassword: true
        });
    }

    handleChange(field, event) {
        this.setState({
            [field]: event.target.value,
            fieldErrors: {}
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.clearErrors();

        const currentPassword = this.state.currentPassword;
        const newPassword1 = this.state.newPassword1;
        const newPassword2 = this.state.newPassword2;

        if (newPassword1 !== newPassword2) {
            this.setState({
                fieldErrors: {
                    newPassword2: 'Passwords do not match.'
                }
            });
            return;
        }
        
        const user = { ...this.props.repo.user };
        user.password = newPassword1;

        this.props.repo.saveUser(user, currentPassword)
            .then(() => this.props.change({ isOpen: false }))
            .catch(e => {
                if (e.response.data.errorFields) {
                    const errs = e.response.data.errorFields;
                    const fieldErrors = {};
                    if (errs.currentPassword) {
                        fieldErrors.currentPassword = errs.currentPassword;
                    }
                    if (errs.password) {
                        fieldErrors.newPassword1 = errs.password;
                    }
                    this.setState({ fieldErrors });
                }
            });
    }

    clearErrors() {
        this.setState({ fieldErrors: {} });
    }

    render() {
        return (
            <Modal title="User Profile"
                isOpen={this.props.isOpen}
                onClose={this.handleClose}
                onShow={this.handleShow}
            >

                <p>Change password.</p>

                <form role="form" className="password-change" onSubmit={e => this.handleSubmit(e)}>
                    <TextField placeholder="Current password" type="password" icon="lock"
                        value={this.state.currentPassword}
                        onChange={e => this.handleChange('currentPassword', e)}
                        error={this.state.fieldErrors.currentPassword}
                        focused={this.state.focusCurrentPassword}
                        resetFocus={() => this.setState({ focusCurrentPassword: false })}
                    />
                    <TextField
                        value={this.state.newPassword1}
                        onChange={e => this.handleChange('newPassword1', e)}
                        error={this.state.fieldErrors.newPassword1}
                        placeholder="New password"
                        type="password"
                        icon="lock"
                    />
                    <TextField
                        value={this.state.newPassword2}
                        onChange={e => this.handleChange('newPassword2', e)}
                        error={this.state.fieldErrors.newPassword2}
                        placeholder="Repeat new password"
                        type="password"
                        icon="lock"
                    />
                    <div className="form-group">
                        <button className="form-control btn btn-primary">Change password</button>
                    </div>
                </form>

            </Modal>
        );
    }

}
