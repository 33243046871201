import React from 'react';

export default class BaseStickersPage extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            stickers: []
        };
        this.updateStickerTag = this.updateStickerTag.bind(this);
    }

    updateStickerTag(stickerTag) {
        this.setState(prevState => ({
            stickers: prevState.stickers.map(s => {
                if (s.id !== stickerTag.stickerId) return s;
                let found = false;
                const stickerTags = s.stickerTags.map(st => {
                    if (st.id !== stickerTag.id) return st;
                    found = true;
                    return stickerTag;
                });
                if (!found) {
                    stickerTags.push(stickerTag);
                }
                return { ...s, stickerTags };
            })
        }));
    }

}
