import React from 'react';
import { Helmet } from 'react-helmet';
import memoize from 'memoize-one';

export default class PageMeta extends React.Component {

    constructor(props) {
        super(props);
        this.deriveMetaImage = memoize(PageMeta.deriveMetaImage);
    }

    static deriveMetaImage(metaImage, stickersForMetaImage, packsForMetaImage) {
        if (metaImage) {
            return metaImage;
        } else if (stickersForMetaImage) {   
            return 'https://stickersearch.net/images/montage/stickers/'
                + stickersForMetaImage.map(s => s.id).slice(0, 8).join('-')
                + '.png?v=4';
        }  else if (packsForMetaImage) {   
            return 'https://stickersearch.net/images/montage/packs/'
                + packsForMetaImage.map(p => p.id).slice(0, 8).join('-')
                + '.png?v=4';
        }
        return null;
    }

    get metaImage() {
        return this.deriveMetaImage(
            this.props.metaImage,
            this.props.stickersForMetaImage,
            this.props.packsForMetaImage);
    }

    render() {
        const metaImage = this.metaImage;

        return (
            <Helmet>
                {this.props.title && [
                    <title key="title">{this.props.title}</title>,
                    <meta property="og:title" content={this.props.title} key="og:title" />
                ]}
                {this.props.description && [
                    <meta name="description" content={this.props.description} key="description" />,
                    <meta property="og:description" content={this.props.description} key="og:description" />
                ]}
                {metaImage && [
                    <link rel="image_src" href={metaImage} key="image_src" />,
                    <meta property="og:image" content={metaImage} key="og:image" />
                ]}
            </Helmet>
        );
    }

}
