import React from 'react';
import Pack from './Pack';
import SearchBox from './SearchBox';
import PageMeta from './PageMeta';
import ProductSlider from './ProductSlider';

export default class PacksPage extends React.PureComponent {

    constructor(props) {
        super(props);
        if (props.data) {
            this.state = { ...props.data };
        } else if (window.__DATA__) {
            this.state = window.__DATA__;
            delete window.__DATA__;
        } else {
            this.state = {
                packs: []
            };
        }
    }

    componentDidMount() {
        if (!this.state.loaded) this.fetch();
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.system !== this.props.system) {
            this.fetch();
        }
    }

    static loadData(props) {
        return props.repo.fetchPacks({
            systemId: props.system.id
        })
        .then(packs => ({
            packs,
            loaded: true
        }));
    }

    fetch() {
        PacksPage.loadData(this.props)
            .then(data => this.setState(data));
    }

    render() {
        const system = this.props.system;

        return (
            <div>
                <PageMeta
                    title={system.name + ' Sticker Packs | Sticker Search'}
                    description={system.name + ' sticker packs. Examine the stickers by choosing the pack.'}
                    packsForMetaImage={this.state.packs}
                />

                <div className="header-container">
                    <div className="header-content">
                        <div>
                            <SearchBox system={system} />
                        </div>
                        <h1>
                            <img src={`/images/logos/${system.slug}-logo.png`} className="system-logo"/>{' '}
                            {system.name} Sticker Packs
                        </h1>
                        <div className="social-share-container">
                            <a className="btn btn-primary btn-sm" role="button" target="_blank"
                                    onClick={() => this.props.share({
                                        url: `https://stickersearch.net/${system.slug}/packs`,
                                        text: system.name + ' Sticker Packs',
                                        hashtags: [ system.name + 'Stickers', 'Stickers' ],
                                        isOpen: true
                                    })}>
                                <i className="fa fa-share-alt"></i> Share
                            </a>
                        </div>
                    </div>
                    <div className="product-container">
                        <div>
                            <ProductSlider
                                keywords="Stickers"
                                sdks={this.props.sdks}
                                repo={this.props.repo}
                            />
                        </div>
                    </div>
                </div>

                <div className="clearfix">
                    {this.state.packs.map(pack =>
                        <Pack pack={pack} key={pack.id} />
                    )}
                </div>

            </div>
        );
    }

}
