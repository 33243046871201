import systems from './systems';
import HomePage from './components/HomePage';
import PacksPage from './components/PacksPage';
import PackPage from './components/PackPage';
import AuthorsPage from './components/AuthorsPage';
import AuthorPage from './components/AuthorPage';
import TagsPage from './components/TagsPage';
import TagPage from './components/TagPage';
import SearchPage from './components/SearchPage';
import EmailTokenPage from './components/EmailTokenPage';

function getSystemBySlug(slug) {
    return systems.find(s => s.slug == slug);
}

export default class SsrRouting {
    
    constructor({ repo, share, login, sdks }) {
        this.repo = repo;
        this.share = share;
        this.login = login;
        this.sdks = sdks;
    }

    getRoutes() {
        return [
            {
                path: '/',
                component: HomePage,
                exact: true,
                deps: () => ({
                    systems,
                    repo: this.repo
                })
            }, {
                path: '/:systemSlug/packs',
                component: PacksPage,
                exact: true,
                deps: match => ({
                    system: getSystemBySlug(match.params.systemSlug),
                    share: this.share,
                    repo: this.repo
                })
            }, {
                path: '/:systemSlug/packs/:slug',
                component: PackPage,
                exact: true,
                deps: match => ({
                    system: getSystemBySlug(match.params.systemSlug),
                    slug: match.params.slug,
                    share: this.share,
                    login: this.login,
                    repo: this.repo,
                    sdks: this.sdks
                })
            }, {
                path: '/authors',
                component: AuthorsPage,
                exact: true,
                deps: () => ({
                    share: this.share,
                    repo: this.repo
                })
            }, {
                path: '/authors/:slug',
                component: AuthorPage,
                exact: true,
                deps: match => ({
                    slug: match.params.slug,
                    systems,
                    share: this.share,
                    repo: this.repo
                })
            }, {
                path: '/:systemSlug/tags',
                component: TagsPage,
                exact: true,
                deps: match => ({
                    system: getSystemBySlug(match.params.systemSlug),
                    share: this.share,
                    repo: this.repo
                })
            }, {
                path: '/:systemSlug/tags/:slug',
                component: TagPage,
                exact: true,
                deps: match => ({
                    system: getSystemBySlug(match.params.systemSlug),
                    slug: match.params.slug,
                    share: this.share,
                    login: this.login,
                    repo: this.repo,
                    sdks: this.sdks
                })
            }, {
                path: '/:systemSlug/search/:query',
                component: SearchPage,
                exact: true,
                deps: match => ({
                    system: getSystemBySlug(match.params.systemSlug),
                    queryString: match.params.query,
                    share: this.share,
                    login: this.login,
                    repo: this.repo,
                    sdks: this.sdks
                })
            }, {
                path: '/et/:hash',
                component: EmailTokenPage,
                exact: true,
                deps: match => ({
                    hash: match.params.hash,
                    repo: this.repo,
                })
            }
        ];
    }

}
