import React from 'react';
import { Link } from 'react-router-dom';
import jQuery from 'jquery';
import '../styles/main.less';
import PasswordModal from './PasswordModal';

if (typeof window !== 'undefined') {
    window.jQuery = jQuery;
    require('bootstrap');
}

export default class Layout extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            passwordData: {}
        };

        this.changePassword = this.changePassword.bind(this);
        this.handleLoginClick = this.handleLoginClick.bind(this);
        this.handleLogoutClick = this.handleLogoutClick.bind(this);
        this.handleUserProfileClick = this.handleUserProfileClick.bind(this);
    }

    changePassword(passwordData) {
        this.setState({ passwordData });
    }

    handleLoginClick(e) {
        this.props.login({ isOpen: true });
    }

    handleLogoutClick(e) {
        this.props.repo.logout();
    }

    handleUserProfileClick() {
        this.changePassword({ isOpen: true });
    }

    render() {
        return (

            <div>
                <nav className="navbar navbar-default">
                    <div className="container">
                        <div className="navbar-header">
                            <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                            <Link to="/" className="navbar-brand">
                                <span><img alt="Brand" src="/images/logo.png"/></span>{' '}
                                Sticker Search
                            </Link>
                        </div>
                        <div className="collapse navbar-collapse" id="navbar">
                            <ul className="nav navbar-nav">
                                <li>
                                    <Link to="/facebook/packs">
                                        <img src="/images/logos/facebook-logo.png" className="system-logo"/>{' '}
                                        Facebook
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/telegram/packs">
                                        <img src="/images/logos/telegram-logo.png" className="system-logo"/>{' '}
                                        Telegram
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/authors">
                                        Authors
                                    </Link>
                                </li>
                                <li>
                                    <a href="#"
                                            onClick={() => this.props.share({
                                                url: 'https://stickersearch.net',
                                                text: 'Search Facebook and Telegram stickers by tags.',
                                                hashtags: [ 'FacebookStickers', 'TelegramStickers', 'Stickers' ],
                                                isOpen: true
                                            })}>
                                        <i className="fa fa-share-alt"></i>
                                    </a>
                                </li>
                            </ul>
                            <ul className="nav navbar-nav navbar-right">

                                {this.props.repo.user ?
                                    <li className="dropdown">
                                        <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                                            <strong>{this.props.repo.user.username}</strong> <span className="caret"></span>
                                        </a>
                                        <ul className="dropdown-menu">
                                            <li><a href="#" title="Change password" onClick={this.handleUserProfileClick}>Change password</a></li>
                                            <li><a href="#" onClick={this.handleLogoutClick}>Sign out</a></li>
                                        </ul>
                                    </li>
                                :
                                    <li>
                                        <button type="button" className="btn btn-default navbar-btn" onClick={this.handleLoginClick}>Sign in</button>
                                    </li>
                                }

                            </ul>
                        </div>
                    </div>
                </nav>
                <div className="container">
                    <main style={{ marginBottom: 100 }}>

                        {this.props.children}

                    </main>
                    <hr/>
                    <footer className="text-center contact">
                        <p>Sticker Search</p>
                        <ul>
                            <li><a href="mailto:info@stickersearch.net"><span className="fa fa-envelope fa-lg"></span></a></li>
                            <li><a href="https://twitter.com/StickerSearch/" target="_blank"><span className="fa fa-twitter fa-lg"></span></a></li>
                            <li><a href="https://www.facebook.com/StickerSearch/" target="_blank"><span className="fa fa-facebook fa-lg"></span></a></li>
                            <li><a href="https://www.vk.com/StickerSearch" target="_blank"><span className="fa fa-vk fa-lg"></span></a></li>
                        </ul>
                    </footer>
                </div>
                <PasswordModal {...this.state.passwordData} change={this.changePassword} repo={this.props.repo} />
            </div>

        );
    }

}
