import React from 'react';
import { withRouter } from 'react-router-dom';

class SearchBox extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = { query: null };
    }

    handleChange(event) {
        this.setState({ query: event.target.value });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.props.history.push(`/${this.props.system.slug}/search/${this.state.query}`);
    }

    render() {
        return (
            <form role="search" onSubmit={this.handleSubmit.bind(this)}>
                <div className="form-group">
                    <div className="input-group">
                        <input type="text" className="form-control" placeholder={`Search ${this.props.system.name} stickers...`}
                                onChange={this.handleChange.bind(this)}
                                value={this.state.query}
                               />
                        <span className="input-group-btn">
                            <button type="submit" className="btn btn-default">Search</button>
                        </span>
                    </div>
                </div>
            </form>
        );
    }

}

export default withRouter(SearchBox);
