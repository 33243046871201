import React from 'react';
import { Link } from 'react-router-dom';

const Pack = (props) => (

    <div className="pack">

        <div className="inactive-stamp">Removed</div>
        <div className="pack-logo">
            <Link to={`/${props.pack.system.slug}/packs/${props.pack.slug}`}><img src={props.pack.logo} /></Link>
        </div>
        <div><Link to={`/${props.pack.system.slug}/packs/${props.pack.slug}`}><strong>{props.pack.name}</strong></Link></div>
        <div><em>{props.pack.author}</em></div>

    </div>

);

export default Pack;
