import React from 'react';
import { Link } from 'react-router-dom';
import StickerTag from './StickerTag';
import StickerImage from './StickerImage';
import $ from 'jquery';

export default class Sticker extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            tagName: null
        };

        this.inputGroupRef = React.createRef();
        this.handleAddTagClick = this.handleAddTagClick.bind(this);
    }

    handleChange(event) {
        this.setState({ tagName: event.target.value });
    }

    handleAddTagClick(event) {
        if (!this.props.repo.isAuthenticated()) {
            this.props.login({ isOpen: true });
            return;
        }

        const tagName = this.state.tagName;

        const newStickerTag = {
            stickerId: this.props.sticker.id,
            tag: {
                name: tagName,
            },
            score: 1
        };

        this.props.repo.saveStickerTag(newStickerTag)
            .then(st => {
                this.setState({ tagName: '' });
                this.props.updateStickerTag(st);

                this.props.sdks.ga.then(ga => {
                    ga('send', 'event', {
                        eventCategory: 'Sticker',
                        eventAction: 'add-tag',
                        eventLabel: tagName 
                    });
                });
            }).catch(error => {
                if (error.response.status === 401) {
                    this.props.repo.logout();
                    this.props.login({ isOpen: true });
                }
                if (error.response.data.errorFields && error.response.data.errorFields.tagName) {
                    this.displayFieldError($(this.inputGroupRef.current), error.response.data.errorFields.tagName);
                }
            });
    }

    displayFieldError(formGroupEl, message) {
        const popover = formGroupEl.popover({
            content: message,
            trigger: 'manual',
            placement: 'top',
            container: formGroupEl
        });
        popover.popover('show');

        $('body').one('click', () => popover.popover('destroy'));
    }

    render() {
        return (
            <div className="sticker">
        
                {this.props.sticker.animation && this.props.sticker.animation.stickerId &&
                    <div className="animated-stamp"><span className="glyphicon glyphicon-play" aria-hidden="true" title="animated"></span></div>
                }
        
                <div className="sticker-img-wrapper">
                    <div className="image-wrapper">
                        <StickerImage sticker={this.props.sticker} />
                    </div>
                    <div className="pack-name">Pack:{' '}
                        <Link to={`/${this.props.sticker.pack.system.slug}/packs/${this.props.sticker.pack.slug}`}>
                            {this.props.sticker.pack.name}
                        </Link>
                    </div>
                </div>
        
                <div className="tags-wrapper">
                    <div className="form-inline">
                        <div className="form-group">
                            <div className="input-group" ref={this.inputGroupRef}>
                                <input type="text" className="form-control" placeholder="Insert tag..."
                                        onChange={this.handleChange.bind(this)}
                                        value={this.state.tagName} />
                                <span className="input-group-btn">
                                    <button type="button" className="btn btn-default" onClick={this.handleAddTagClick}>Add</button>
                                </span>
                            </div>
                        </div>
                    </div>
                    <ul className="tags list-group">
                        {this.props.sticker.stickerTags.map(stickerTag =>
                            <StickerTag
                                key={stickerTag.id}
                                stickerTag={stickerTag}
                                system={this.props.sticker.pack.system}
                                repo={this.props.repo}
                                login={this.props.login}
                                sdks={this.props.sdks}
                                updateStickerTag={this.props.updateStickerTag} />
                        )}
                    </ul>
                </div>
        
            </div>
        );
    }

}
