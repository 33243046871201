import React from 'react';
import $ from 'jquery';
import TextField from './TextField';
import Modal from './Modal';

export default class LoginModal extends React.Component {

    constructor(props) {
        super(props);

        this.tabsRef = React.createRef();
        this.signInEmailRef = React.createRef();

        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.clearErrors = this.clearErrors.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.handleSwitchTab = this.handleSwitchTab.bind(this);

        this.state = {
            signInEmail: '',
            signInPassword: '',

            registerEmail: '',
            registerPassword: '',
            registerPassword2: '',

            passwordResetEmail: '',

            focusedField: null,
            fieldErrors: {}
        };
    }

    handleClose() {
        this.props.login({ isOpen: false });
        this.clearForm();
    }

    handleShow() {
        this.setState({ focusedField: 'signInEmail' });
        $(this.tabsRef.current).find('a[data-toggle="tab"]:first').tab('show');
    }

    componentDidMount() {
        $(this.tabsRef.current).find('a[data-toggle="tab"]').on('shown.bs.tab', e => {
            this.clearForm();
            switch (e.target.hash) {
                case '#signin':         this.setState({ focusedField: 'signInEmail' }); break;
                case '#register':       this.setState({ focusedField: 'registerEmail' }); break;
                case '#password-reset': this.setState({ focusedField: 'passwordResetEmail' }); break;
                default: this.setState({ focusedField: null });
            }
        });
    }

    clearForm() {
        this.setState({
            signInEmail: '',
            signInPassword: '',
            registerEmail: '',
            registerPassword: '',
            registerPassword2: '',
            passwordResetEmail: '',
            fieldErrors: {}
        });
    }

    handleChange(field, event) {
        this.setState({
            [field]: event.target.value
        });
        this.clearErrors();
    }

    handleSignin(e) {
        e.preventDefault();
        this.clearErrors();

        const email = this.state.signInEmail;
        const password = this.state.signInPassword;
        this.signIn(email, password);
    }
    
    signIn(email, password) {
        this.props.repo.authenticate(email, password)
            .then(() => {
                this.props.login({ isOpen: false });
            }).catch(e => {
                if (e.response.data.errorFields) {
                    const fieldErrors = {};
                    if (e.response.data.errorFields.username) {
                        fieldErrors.signInEmail = e.response.data.errorFields.username;
                    }
                    if (e.response.data.errorFields.password) {
                        fieldErrors.signInPassword = e.response.data.errorFields.password;
                    }
                    this.setState({ fieldErrors });
                }
            });
    }

    handleRegister(e) {
        e.preventDefault();
        this.clearErrors();

        const email = this.state.registerEmail;
        const password = this.state.registerPassword;
        const password2 = this.state.registerPassword2;

        if (password !== password2) {
            this.setState({
                fieldErrors: {
                    registerPassword2: 'Passwords do not match.'
                }
            });
            return;
        }
        
        this.props.repo.saveUser({
            id: null,
            username: email,
            password: password
        }).then(() => {
            this.signIn(email, password);
        }).catch(e => {
            if (e.response.data.errorFields) {
                const fieldErrors = {};
                if (e.response.data.errorFields.username) {
                    fieldErrors.registerEmail = e.response.data.errorFields.username;
                }
                if (e.response.data.errorFields.password) {
                    fieldErrors.registerPassword = e.response.data.errorFields.password;
                }
                this.setState({ fieldErrors });
            }
        });
    }

    handlePasswordReset(e) {
        e.preventDefault();
        this.clearErrors();

        const { repo, login } = this.props;
        
        const emailToken = {};
        emailToken.username = this.state.passwordResetEmail;
        emailToken.action = 'PASSWORD_RESET';

        repo.saveEmailToken(emailToken)
            .then(() => login({ isOpen: false }))
            .catch(e => {
                if (e.response.data.errorFields) {
                    if (e.response.data.errorFields.username) {
                        this.setState({
                            fieldErrors: { passwordResetEmail: e.response.data.errorFields.username }
                        });
                    }
                }
            });
    }

    handleSwitchTab(e, hash) {
        e.preventDefault();
        $(this.tabsRef.current).find(`a[href="${hash}"]`).tab('show');
    }

    clearErrors() {
        this.setState({ fieldErrors: {} });
    }

    render() {
        return (
            <Modal size="md" title="Sign in / Register"
                isOpen={this.props.isOpen}
                onClose={this.handleClose}
                onShow={this.handleShow}
            >

                <ul className="nav nav-tabs" role="tablist" ref={this.tabsRef}>
                    <li role="presentation" className="active"><a href="#signin" aria-controls="signin" role="tab" data-toggle="tab">Sign in</a></li>
                    <li role="presentation" className=""><a href="#register" aria-controls="register" role="tab" data-toggle="tab">Register</a></li>
                    <li role="presentation" className=""><a href="#password-reset" aria-controls="password-reset" role="tab" data-toggle="tab">Reset Password</a></li>
                </ul>

                <div className="tab-content">
                    <div role="tabpanel" className="tab-pane fade in active" id="signin">
                        
                        <p>Sign in if you're an <strong>existing user</strong>.</p>

                        <form role="form" className="signin" onSubmit={e => this.handleSignin(e)}>
                            <TextField placeholder="Email" type="text" icon="envelope"
                                value={this.state.signInEmail}
                                onChange={e => this.handleChange('signInEmail', e)}
                                error={this.state.fieldErrors.signInEmail}
                                focused={this.state.focusedField === 'signInEmail'}
                                resetFocus={() => this.setState({ focusedField: null })}
                            />
                            <TextField placeholder="Password" type="password" icon="lock"
                                value={this.state.signInPassword}
                                onChange={e => this.handleChange('signInPassword', e)}
                                error={this.state.fieldErrors.signInPassword}
                            />
                            <div className="form-group">
                                <button type="submit" className="form-control btn btn-primary">Sign in</button>
                            </div>
                        </form>

                        <div className="row">
                            <div className="col-xs-6 text-center">
                                <a href="#" onClick={e => this.handleSwitchTab(e, '#password-reset')}>Forgot password?</a>
                            </div>
                            <div className="col-xs-6 text-center">
                                <a href="#" onClick={e => this.handleSwitchTab(e, '#register')}>New user?</a>
                            </div>
                        </div>

                    </div>

                    <div role="tabpanel" className="tab-pane fade" id="register">

                        <p>Register if you're a <strong>new user</strong>.</p>

                        <form role="form" className="register" onSubmit={e => this.handleRegister(e)}>
                            <TextField placeholder="Email" type="text" icon="envelope"
                                value={this.state.registerEmail}
                                onChange={e => this.handleChange('registerEmail', e)}
                                error={this.state.fieldErrors.registerEmail}
                                focused={this.state.focusedField === 'registerEmail'}
                                resetFocus={() => this.setState({ focusedField: null })}
                            />
                            <TextField placeholder="Password" type="password" icon="lock"
                                value={this.state.registerPassword}
                                onChange={e => this.handleChange('registerPassword', e)}
                                error={this.state.fieldErrors.registerPassword}
                            />
                            <TextField placeholder="Repeat password" type="password" icon="lock"
                                value={this.state.registerPassword2}
                                onChange={e => this.handleChange('registerPassword2', e)}
                                error={this.state.fieldErrors.registerPassword2}
                            />
                            <div className="form-group">
                                <button type="submit" className="form-control btn btn-primary">Register</button>
                            </div>
                        </form>

                        <div className="row">
                            <div className="col-xs-6 text-center">
                                <a href="#" onClick={e => this.handleSwitchTab(e, '#signin')}>Existing user?</a>
                            </div>
                            <div className="col-xs-6 text-center">
                                <a href="#" onClick={e => this.handleSwitchTab(e, '#password-reset')}>Forgot password?</a>
                            </div>
                        </div>

                    </div>

                    <div role="tabpanel" className="tab-pane fade" id="password-reset">

                        <p>Enter your email to receive the password reset link.</p>

                        <form role="form" className="password-reset" onSubmit={e => this.handlePasswordReset(e)}>
                            <TextField placeholder="Email" type="text" icon="envelope"
                                value={this.state.passwordResetEmail}
                                onChange={e => this.handleChange('passwordResetEmail', e)}
                                error={this.state.fieldErrors.passwordResetEmail}
                                focused={this.state.focusedField === 'passwordResetEmail'}
                                resetFocus={() => this.setState({ focusedField: null })}
                            />
                            <div className="form-group">
                                <button className="form-control btn btn-primary">Reset Password</button>
                            </div>
                        </form>

                    </div>

                </div>

            </Modal>

        );
    }

}
