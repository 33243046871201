import jQuery from 'jquery';
import autotrack from 'autotrack';

export default class Sdks {

    constructor() {
        this._facebook = null;
        this._twitter = null;
        this._ga = null;
    }

    get facebook() {
        return this._facebook = this._facebook ||
            jQuery.getScript('//connect.facebook.net/en_US/sdk.js').then(() => {
                FB.init({ version: 'v2.5', xfbml: false });
                return FB;
            });
    }

    get twitter() {
        return this._twitter = this._twitter ||
            jQuery.getScript('https://platform.twitter.com/widgets.js').then(() => {
                return twttr;
            });
    }

    get ga() {
        return this._ga = this._ga ||
            jQuery.getScript('//www.google-analytics.com/analytics.js').then(() => {
                ga('create', 'UA-60478279-1', 'auto');
                ga('require', 'autotrack');     // Include automatic + enhanced analytics.
                ga('send', 'pageview');
                return ga;
            });
    }

}
