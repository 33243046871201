import React from 'react';
import { capitalize } from '../util';
import Sticker from './Sticker';
import SearchBox from './SearchBox';
import BaseStickersPage from './BaseStickersPage';
import PageMeta from './PageMeta';
import ProductSlider from './ProductSlider';

export default class SearchPage extends BaseStickersPage {

    constructor(props) {
        super(props);

        if (props.data) {
            this.state = { ...props.data };
        } else if (window.__DATA__) {
            this.state = window.__DATA__;
            delete window.__DATA__;
        }

        this.handleShare = this.handleShare.bind(this);
    }

    componentDidMount() {
        if (!this.state.loaded) this.fetch();
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.system !== this.props.system || prevProps.queryString !== this.props.queryString || prevProps.user !== this.props.user) {
            this.fetch();
        }
    }

    static loadData(props) {
        return props.repo.fetchStickers({
            q: props.queryString, 
            systemId: props.system.id
        })
        .then(stickers => ({
            stickers,
            loaded: true
        }));
    }

    fetch() {
        SearchPage.loadData(this.props)
            .then(data => this.setState(data));
    }

    handleShare() {
        this.props.share({
            url: `https://stickersearch.net/${this.props.system.slug}/search/${this.props.queryString}`,
            text: `${this.props.system.name} Stickers Search Result for "${this.props.queryString}".`,
            hashtags: [
                'Stickers',
                this.props.system.name + 'Stickers',
                capitalize(this.props.queryString).replace(/ /g, '')
            ],
            isOpen: true
        });
    }

    render() {
        const { queryString, system } = this.props;
        const stickers = this.state.stickers;

        return (
            <div>
                <PageMeta
                    title={`${system.name} Stickers Search Result for "${queryString}" | Sticker Search`}
                    description={`${system.name} stickers matched by "${queryString}". Search stickers by tags.`}
                    stickersForMetaImage={stickers}
                />

                <div className="header-container">
                    <div className="header-content">
                        <div>
                            <SearchBox system={system} />
                        </div>

                        <h1>
                            <img src={`/images/logos/${system.slug}-logo.png`} className="system-logo"/>{' '}
                            Search Result for "{queryString}"{' '}
                            <small>{system.name} Stickers</small>
                        </h1>
                        <div className="social-share-container">
                            <a className="btn btn-primary btn-sm" role="button" target="_blank"
                                    onClick={this.handleShare}>
                                <i className="fa fa-share-alt"></i> Share
                            </a>
                        </div>

                    </div>
                    <div className="product-container">
                        <div>
                            <ProductSlider
                                keywords={queryString}
                                sdks={this.props.sdks}
                                repo={this.props.repo}
                            />
                        </div>
                    </div>
                </div>

                <div className="clearfix">
                    {stickers.map(sticker =>
                        <Sticker
                            key={sticker.id}
                            sticker={sticker}
                            system={system}
                            repo={this.props.repo}
                            login={this.props.login}
                            sdks={this.props.sdks}
                            updateStickerTag={this.updateStickerTag} />
                    )}
                </div>

            </div>
        );
    }

}
