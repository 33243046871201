import React from 'react';

class TextField extends React.PureComponent {

    constructor(props) {
        super(props);
        this.inputFieldRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.focused) {
            this.inputFieldRef.current.focus();
            this.props.resetFocus();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.focused && this.props.focused !== prevProps.focused) {
            this.inputFieldRef.current.focus();
            this.props.resetFocus();
        }
    }

    render() {
        return (
            <div className={'form-group ' + (this.props.error && ' has-error')}>
                <div className="input-group">
                    <span className="input-group-addon">
                        <span className={`glyphicon glyphicon-${this.props.icon}`}></span>
                    </span>
                    <input className="form-control"
                            ref={this.inputFieldRef}
                            type={this.props.type}
                            placeholder={this.props.placeholder}
                            value={this.props.value}
                            onChange={this.props.onChange} />
                </div>
                {this.props.error &&
                    <span className="help-block">{this.props.error}</span>
                }
            </div>
        );
    }
}

export default TextField;
