import React from 'react';
import AuthorItem from './AuthorItem';
import PageMeta from './PageMeta';

export default class AuthorsPage extends React.PureComponent {

    constructor(props) {
        super(props);
        if (props.data) {
            this.state = { ...props.data };
        } else if (window.__DATA__) {
            this.state = window.__DATA__;
            delete window.__DATA__;
        } else {
            this.state = {
                authors: []
            }
        }
    }

    componentDidMount() {
        if (!this.state.loaded) this.fetch();
    }

    static loadData(props) {
        return props.repo.fetchAuthors()
            .then(authors => ({
                authors,
                loaded: true
            }));
    }

    fetch() {
        AuthorsPage.loadData(this.props)
            .then(data => this.setState(data));
    }

    handleShare() {
        this.props.share({
            url: `https://stickersearch.net/authors`,
            text: 'Sticker Authors',
            hashtags: [ 'Stickers', 'Illustrators', 'Designers', 'StickerCreators' ],
            isOpen: true
        });
    }

    render() {
        return (
            <div>
                <PageMeta
                    title="Sticker Authors | Sticker Search"
                    description="The list of sticker creators."
                />

                <div className="header-container">
                    <div className="header-content">
                        <h1>
                            Sticker Authors
                        </h1>
                        <div className="social-share-container">
                            <a className="btn btn-primary btn-sm" role="button"
                                    onClick={this.handleShare.bind(this)}>
                                <i className="fa fa-share-alt"></i> Share
                            </a>
                        </div>
                    </div>
                </div>

                <div className="clearfix">
                    {this.state.authors.map(author =>
                        <AuthorItem author={author} key={author.id} />
                    )}
                </div>
                
            </div>
        );
    }

}
