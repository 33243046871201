import React from 'react';
import PageMeta from './PageMeta';
import TextField from './TextField';

export default class EmailTokenPage extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            token: null,
            error: null,
            successMessage: '',
            errorMessage:'',
            newPassword1: '',
            newPassword2: '',
            fieldErrors: {},
            finished: false
        };
    }

    componentDidMount() {
        this.props.repo.fetchEmailToken(this.props.hash)
            .then(token => {
                this.setState({
                    token,
                    successMessage: token.action === 'EMAIL_CONFIRMATION'
                        ? 'Your email has been confirmed. Thank you.'
                        : ''
                })
            })
            .catch(() => this.setState({
                errorMessage: 'This token is not available any longer.'
            }));
    }

    handleChange(field, event) {
        this.setState({
            [field]: event.target.value,
            fieldErrors: {}
        });
    }

    handleSubmit(e) {
        e.preventDefault();

        if (!this.state.token.userId) return;

        const newPassword1 = this.state.newPassword1;
        const newPassword2 = this.state.newPassword2;

        if (newPassword1 !== newPassword2) {
            this.setState({
                fieldErrors: {
                    newPassword2: 'Passwords do not match.'
                }
            });
            return;
        }

        const patch = { password: newPassword1 };
        this.props.repo.patchUserWithToken(this.state.token.userId, patch, this.state.token.id)
            .then(() => {
                this.setState({
                    successMessage: 'Your password has been successfully changed.',
                    finished: true
                });
            })
            .catch(e => {
                const fieldErrors = {};
                let errorMessage = '';
                switch (e.response.status) {
                    case 401: errorMessage = 'Token not available any longer.'; break;
                    case 500: errorMessage = 'Server error.'; break;
                    case 0: errorMessage = 'Server not responding'; break;
                }
                if (e.response.data.errorFields) {
                    const errs = e.response.data.errorFields;
                    if (errs.password) {
                        fieldErrors.newPassword1 = errs.password;
                    }
                }
                this.setState({ fieldErrors, errorMessage });
            });
    }

    render() {
        let actionName = '';
        let headerIcon = '';

        if (this.state.token) {
            if (this.state.token.action === 'EMAIL_CONFIRMATION') {
                actionName = 'Email Confirmation';
                headerIcon = 'glyphicon-envelope';
            } else if (this.state.token.action === 'PASSWORD_RESET') {
                actionName = 'Password Reset';
                headerIcon = 'glyphicon-lock';
            }
        }

        return (
            <div>

                {this.state.token &&
                    <div className="page-header" data-hook="header">
                        <h1>
                            <span>{actionName}</span>
                            <small> <span className={'glyphicon ' + headerIcon}></span> </small>
                        </h1>
                    </div>
                }

                {this.state.successMessage &&
                    <div className="alert alert-success" role="alert" data-hook="success-alert">
                        <strong>{this.state.successMessage}</strong>
                    </div>
                }

                {this.state.errorMessage &&
                    <div className="alert alert-danger" role="alert" data-hook="error-alert">
                        <strong>{this.state.errorMessage}</strong>
                    </div>
                }

                {!this.state.finished && this.state.token && this.state.token.action === 'PASSWORD_RESET' &&

                    <div data-hook="password-reset">

                        <p>Enter your new password.</p>

                        <div className="row">

                            <form role="form" className="col-sm-5 password-change" onSubmit={e => this.handleSubmit(e)}>
                                <TextField
                                    value={this.state.newPassword1}
                                    onChange={e => this.handleChange('newPassword1', e)}
                                    error={this.state.fieldErrors.newPassword1}
                                    placeholder="New password"
                                    type="password"
                                    icon="lock"
                                />
                                <TextField
                                    value={this.state.newPassword2}
                                    onChange={e => this.handleChange('newPassword2', e)}
                                    error={this.state.fieldErrors.newPassword2}
                                    placeholder="Repeat new password"
                                    type="password"
                                    icon="lock"
                                />
                                <div className="form-group">
                                    <button className="form-control btn btn-primary">Change password</button>
                                </div>
                            </form>

                        </div>

                    </div>
                }

            </div>
        );
    }

}
