import React from 'react';

export default class Sticker extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {};
        this.handleLink = this.handleLink.bind(this);
    }

    componentDidMount() {
        this.fetch();
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.keywords !== this.props.keywords) {
            this.fetch();
        }
    }

    fetch() {
        this.props.repo.fetchProducts({
            keywords: this.props.keywords
        })
        .then(products => this.setState({ products }));
    }
    
    handleLink(product) {
        this.props.sdks.ga.then(ga => {
            ga('send', 'event', {
                eventCategory: 'Product',
                eventAction: 'click',
                eventLabel: product.asin
            });
        });
    }

    render() {
        if (!this.state.products) return null;

        const postfix = this.props.postfix ? ('-' + this.props.postfix) : '';

        return (

            <div id={'products-carousel' + postfix} className="carousel slide" data-ride="carousel" data-interval="7000">

                {/* Wrapper for slides */}
                <div className="carousel-inner" role="listbox">
                    {this.state.products.map((product, index) =>
                        
                        <div className={'item ' + (index === 0 ? 'active ' : '') + 'product-item'}>
                            <div className="product-image">
                                <a href={product.link} target="_blank" onClick={() => this.handleLink(product)}>
                                    <img src={product.image} alt={product.title} />
                                </a>
                            </div>
                            <div className="product-info">
                                <div className="product-title">
                                    <a href={product.link} target="_blank" onClick={() => this.handleLink(product)}>
                                        {product.title}
                                    </a>
                                </div>
                                <div className="product-price"><span>{product.price}</span></div>
                            </div>
                        </div>

                    )}
                </div>

                {/* Controls */}
                <a className="left carousel-control" href={'#products-carousel' + postfix} role="button" data-slide="prev">
                    <span className="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="right carousel-control" href={'#products-carousel' + postfix} role="button" data-slide="next">
                    <span className="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>

            </div>
        );
    }

}
