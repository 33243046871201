import React from 'react';
import Modal from './Modal';
import memoize from 'memoize-one';

export default class ShareModal extends React.Component {

    constructor(props) {
        super(props);
        this.deriveUrls = memoize(ShareModal.deriveUrls);
        this.handleClose = this.handleClose.bind(this);
    }

    static deriveUrls(url, text, hashtags) {
        const urlEncoded = encodeURIComponent(url);
        return {
            facebookUrl: 'https://www.facebook.com/share.php?u=' + urlEncoded,
            twitterUrl: 'https://twitter.com/intent/tweet?url=' + urlEncoded +
                            '&text=' + encodeURIComponent(text) +
                            '&hashtags=' + (hashtags ? hashtags.join(',') : '') +
                            '&via=StickerSearch',
            redditUrl: 'https://www.reddit.com/submit?url=' + urlEncoded,
            vkUrl: 'https://vkontakte.ru/share.php?url=' + urlEncoded,
            googlePlusUrl: 'https://plus.google.com/share?url=' + urlEncoded
        }
    }

    get urls() {
        return this.deriveUrls(this.props.url, this.props.text, this.props.hashtags);
    }

    handleClose() {
        this.props.share({ isOpen: false });
    }

    render() {
        const url = this.props.url;
        const urls = this.urls;

        return (
            <Modal
                isOpen={this.props.isOpen}
                onClose={this.handleClose}
                title={<React.Fragment><i className="fa fa-share-alt"></i> Share</React.Fragment>}
            >
                <p>
                    <strong>Share URL:</strong>
                    {' '}
                    <span>{url}</span>
                </p>

                <hr/>

                <ul className="list-unstyled share-buttons">
                    <li><a href={urls.facebookUrl} target="_blank" className="facebook"><i className="fa fa-fw fa-facebook"></i> Facebook</a></li>
                    <li><a href={urls.twitterUrl} target="_blank" className="twitter"><i className="fa fa-fw fa-twitter"></i> Twitter</a></li>
                    <li><a href={urls.redditUrl} target="_blank" className="reddit"><i className="fa fa-fw fa-reddit-alien"></i> Reddit</a></li>
                    <li><a href={urls.vkUrl} target="_blank" className="vk"><i className="fa fa-fw fa-vk"></i> VK</a></li>
                    <li><a href={urls.googlePlusUrl} target="_blank" className="google-plus"><i className="fa fa-fw fa-google-plus"></i> Google+</a></li>
                </ul>
            </Modal>
        );
    }

}
