import React from 'react';
import PageMeta from './PageMeta';

export default class NotFoundPage extends React.PureComponent {

    render() {
        return (
            <div>
                <PageMeta
                    title="Not Found | Sticker Search"
                    description="The requested page is not found."
                />

                <div className="header-container">
                    <div className="header-content">
                        <h1>
                            Not Found
                        </h1>
                    </div>
                </div>
                
            </div>
        );
    }

}
