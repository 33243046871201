import React from 'react';
import { Link } from 'react-router-dom';

export default class StickerTag extends React.PureComponent {

    handleUpvote() {
        if (!this.props.repo.isAuthenticated()) {
            this.props.login({ isOpen: true });
            return;
        }

        // Copy sticker tag:
        const stickerTag = {
            ...this.props.stickerTag,
            vote: {
                ...this.props.stickerTag.vote
            }
        }

        const vote = stickerTag.vote;

        vote.stickerTagId = this.props.stickerTag.id;
        vote.userId = this.props.repo.user.id;

        if (vote.voteType === 'UP') {
            vote.voteType = 'NONE';
            stickerTag.score--;
        } else if (vote.voteType === 'DOWN') {
            vote.voteType = 'UP';
            stickerTag.score += 2;
        } else {
            vote.voteType = 'UP';
            stickerTag.score++;
        }

        this.props.repo.saveVote(vote)
            .then(vote => {
                stickerTag.vote = vote;
                this.props.updateStickerTag(stickerTag);

                this.props.sdks.ga.then(ga => {
                    ga('send', 'event', {
                        eventCategory: 'Sticker',
                        eventAction: 'upvote-tag',
                        eventLabel: stickerTag.tag.name
                    });
                });
            })
            .catch(e => {
                if (e.response.status === 401) {
                    this.props.repo.logout();
                    this.props.login({ isOpen: true });
                }
            });
    }

    handleDownvote() {
        if (!this.props.repo.isAuthenticated()) {
            this.props.login({ isOpen: true });
            return;
        }

        // Copy sticker tag:
        const stickerTag = {
            ...this.props.stickerTag,
            vote: {
                ...this.props.stickerTag.vote
            }
        }

        const vote = stickerTag.vote;

        vote.stickerTagId = this.props.stickerTag.id;
        vote.userId = this.props.repo.user.id;

        if (vote.voteType === 'DOWN') {
            vote.voteType = 'NONE';
            stickerTag.score++;
        } else if (vote.voteType === 'UP') {
            vote.voteType = 'DOWN';
            stickerTag.score -= 2;
        } else {
            vote.voteType = 'DOWN';
            stickerTag.score--;
        }

        this.props.repo.saveVote(vote)
            .then(vote => {
                stickerTag.vote = vote;
                this.props.updateStickerTag(stickerTag);

                this.props.sdks.ga.then(ga => {
                    ga('send', 'event', {
                        eventCategory: 'Sticker',
                        eventAction: 'downvote-tag',
                        eventLabel: stickerTag.tag.name
                    });
                });
            })
            .catch(e => {
                if (e.response.statusCode === 401) {
                    this.props.repo.logout();
                    this.props.login({ isOpen: true });
                }
            });
    }

    render() {
        const stickerTag = this.props.stickerTag;
        const system = this.props.system;

        return (
            <li className={'list-group-item' + (stickerTag.hasNegativeScore ? ' negative-score' : '')}>
                <button className={'vote-btn' + (stickerTag.vote.voteType === 'UP' ? ' active' : '')}
                        onClick={this.handleUpvote.bind(this)}>
                    <span className="upvote" aria-hidden="true"></span>
                </button>
                <button className={'vote-btn' + (stickerTag.vote.voteType === 'DOWN' ? ' active' : '')}
                        onClick={this.handleDownvote.bind(this)}>
                    <span className="downvote" aria-hidden="true"></span>
                </button>
                <Link to={`/${system.slug}/tags/${stickerTag.tag.slug}`}>{stickerTag.tag.name}</Link>
                <span className="badge">{stickerTag.score}</span>
            </li>
        );
    }

}
