import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from './Layout';
import ShareModal from './ShareModal';
import LoginModal from './LoginModal';
import NotFoundPage from './NotFoundPage';
import SsrRouting from '../routes';

export default class Root extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            shareData: {},
            loginData: {}
        };

        this.share = this.share.bind(this);
        this.login = this.login.bind(this);

        this.repo = props.repo;
        this.sdks = props.sdks;

        this.ssrRouting = new SsrRouting({
            repo: this.repo,
            share: this.share,
            login: this.login,
            sdks: this.sdks
        });
    }

    share(shareData) {
        this.setState({ shareData });
    }

    login(loginData) {
        this.setState({ loginData });
    }

    render() {
        return (
            <div>
                <Layout share={this.share} login={this.login} repo={this.repo}>
                    <Switch>
                        {this.ssrRouting.getRoutes().map(route =>
                            <Route exact path={route.path} key={route.path} render={props =>
                                <route.component {...route.deps(props.match)} data={this.props.data} user={this.props.user} />
                            } />
                        )}
                        <Route render={() => <NotFoundPage />} />
                    </Switch>
                </Layout>
                
                <ShareModal {...this.state.shareData} share={this.share} />
                <LoginModal {...this.state.loginData} login={this.login} repo={this.repo} />

            </div>
        );
    }

}
