import React from 'react';
import { Router } from 'react-router-dom';
import createHistory from 'history/createBrowserHistory';
import Root from './Root';
import Repo from '../repo';
import Sdks from '../sdks';

export default class AppClient extends React.Component {

    constructor(props) {
        super(props);

        this.repo = new Repo({
            onUnauth: console.error,
            onServerError: console.error,
            storage: global.localStorage,
            onUserChange: user => this.setState({ user })
        });
        
        this.context = {};
        this.sdks = new Sdks();

        this.history = createHistory();
        this.history.listen(location => {
            this.sdks.ga.then(ga => {
                ga('send', 'pageview', location.pathname);
            });
        });

        this.state = {
            user: null
        };
    }
    
    render() {
        return (
            <Router history={this.history}>
                <Root repo={this.repo} sdks={this.sdks} user={this.state.user} />
            </Router>
        );
    }

}
