import axios from 'axios';
import qs from 'qs';

const LOGIN_URL = '/api/auth';

export default class Repo {

    constructor({
            onUnauth, onServerError, onUserChange,
            storage, baseUrl, headers = {}
    }) {

        this.storage = storage;
        this.onUserChange = onUserChange;
        this.load();

        this.axios = axios.create({
            headers: {
                ...headers,
                'X-Requested-With': 'XMLHttpRequest'
            },
            withCredentials: true,
            baseURL: baseUrl ? baseUrl : '/'
        });

        this.axios.interceptors.request.use(config => {
            if (this.isAuthenticated()) {
                // config.headers.authorization = 'Bearer ' + this.token;
            }
            return config;
        });

        this.axios.interceptors.response.use(response => {
            return response;
        }, error => {
            return Promise.reject(error);
            if (error.response.status === 401 && error.response.config.url !== LOGIN_URL) {
                this.clearAuth();
                onUnauth(error);
            } else if (error.response.status >= 500 && error.response.status < 600) {
                onServerError(error);
            }
            return Promise.reject(error);
        });

    }

    authenticate(email, password) {
        return this.axios({
            method: 'GET',
            url: LOGIN_URL,
            headers: {
                'Auth-Email': email,
                'Auth-Password': password
            }
        }).then(response => {
            this.user = response.data.user;
            if (this.onUserChange) this.onUserChange(this.user);
            this.store();
        });
    }

    logout() {
        return this.axios({
            method: 'GET',
            url: LOGIN_URL,
            headers: {
                'Logout': true
            }
        }).then(() => {
            this.clearAuth();
            if (this.onUserChange) this.onUserChange(null);
        });
    }

    isAuthenticated() {
        return this.user ? true : false;
    }

    saveUser(user, currentPassword = null) {
        return (user.id
            ? this.axios.put('/api/users/' + user.id, user, {
                headers: {
                    'Current-Password': currentPassword
                }
            })
            : this.axios.post('/api/users', user)
        ).then(response => response.data);
    }

    patchUserWithToken(userId, patch, token) {
        return this.axios.patch('/api/users/' + userId, patch, {
            headers: {
                'Email-Token': token
            }
        }).then(response => response.data);
    }

    fetchEmailToken(id) {
        return this.axios.get('/api/email-tokens/' + id)
            .then(response => response.data);
    }

    saveEmailToken(emailToken) {
        return this.axios.post('/api/email-tokens', emailToken);
    }

    fetchStickers(params) {
        return this.axios.get('/api/stickers?' + qs.stringify(params))
            .then(response => response.data);
    }

    fetchPacks(params) {
        return this.axios.get('/api/packs?' + qs.stringify(params))
            .then(response => response.data);
    }

    fetchTags(params) {
        return this.axios.get('/api/tags?' + qs.stringify(params))
            .then(response => response.data);
    }

    fetchAuthors(params) {
        return this.axios.get('/api/authors?' + qs.stringify(params))
            .then(response => response.data);
    }

    fetchProducts(params) {
        return this.axios.get('/api/products?' + qs.stringify(params))
            .then(response => response.data);
    }

    saveStickerTag(stickerTag) {
        return this.axios.post('/api/stickers/' + stickerTag.stickerId + '/sticker-tags', stickerTag)
            .then(response => response.data);
    }
    
    saveVote(vote) {
        return (vote.id
            ? this.axios.put('/api/votes/' + vote.id, vote)
            : this.axios.post('/api/votes', vote)
        ).then(response => response.data);
    }

    load() {
        if (!this.storage) return;
        
        const user = JSON.parse(this.storage.getItem('stickers:user'));
        if (user) {
            this.user = user;
        }
    }

    store() {
        if (this.user) {
            this.storage.setItem('stickers:user', JSON.stringify(this.user));
        } else {
            this.storage.removeItem('stickers:user');
        }
    }

    clearAuth() {
        this.user = null;
        this.store();
    }

}
