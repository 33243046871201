import React from 'react';
import Sticker from './Sticker';
import SearchBox from './SearchBox';
import { capitalize } from '../util';
import BaseStickersPage from './BaseStickersPage';
import PageMeta from './PageMeta';
import ProductSlider from './ProductSlider';

export default class TagPage extends BaseStickersPage {

    constructor(props) {
        super(props);
        if (props.data) {
            this.state = { ...props.data };
        } else if (window.__DATA__) {
            this.state = window.__DATA__;
            delete window.__DATA__;
        } else {
            this.state ={
                tag: null
            };
        }
    }

    componentDidMount() {
        if (!this.state.loaded) this.fetch();
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.system !== this.props.system || prevProps.slug !== this.props.slug || prevProps.user !== this.props.user) {
            this.fetch();
        }
    }

    static loadData(props) {
        return Promise.all([
            props.repo.fetchStickers({
                tagSlug: props.slug,
                systemId: props.system.id
            }),
            props.repo.fetchTags({
                systemId: props.system.id
            })
        ])
        .then(([ stickers, tags ]) => ({
            stickers,
            tag: tags.find(t => t.slug === props.slug),
            loaded: true
        }));
    }

    fetch() {
        TagPage.loadData(this.props)
            .then(data => this.setState(data));
    }
    
    handleShare() {
        if (!this.state.tag) return;

        const capitalized = capitalize(this.state.tag.name);
        const camelCased = capitalize(this.state.tag.name.replace(/\W/g, ' ')).replace(/ /g, '');

        this.props.share({
            sdks: this.props.sdks,
            url: `https://stickersearch.net/${this.props.system.slug}/tags/${this.state.tag.slug}`,
            text: `${capitalized} ${this.props.system.name} Stickers.`,
            hashtags: [
                this.props.system.name + 'Stickers', 'Stickers',
                camelCased
            ],
            isOpen: true
        });
    }

    render() {
        const system = this.props.system;
        const { tag, stickers } = this.state;

        if (!tag) return null;

        const capitalizedTagName = capitalize(tag.name);

        return (
            <div>
                <PageMeta
                    title={`${capitalizedTagName} ${system.name} Stickers | Sticker Search`}
                    description={`${system.name} stickers tagged as "${capitalizedTagName}"`}
                    stickersForMetaImage={stickers}
                />

                <div className="header-container">
                    <div className="header-content">
                        <div>
                            <SearchBox system={system} />
                        </div>
                        
                        <h1>
                            <img src={`/images/logos/${system.slug}-logo.png`} className="system-logo"/>{' '}
                            <span>{tag.name}</span>{' '}
                            <small>{system.name} Sticker Tag</small>
                        </h1>
                        <div className="social-share-container">
                            <a className="btn btn-primary btn-sm" role="button" target="_blank"
                                    onClick={this.handleShare.bind(this)}>
                                <i className="fa fa-share-alt"></i> Share
                            </a>
                        </div>
                        
                    </div>
                    <div className="product-container">
                        <div>
                            <ProductSlider
                                keywords={this.props.slug}
                                sdks={this.props.sdks}
                                repo={this.props.repo}
                            />
                        </div>
                    </div>
                </div>

                <div className="clearfix">
                    {stickers.map(sticker =>
                        <Sticker
                            key={sticker.id}
                            sticker={sticker}
                            system={system}
                            repo={this.props.repo}
                            login={this.props.login}
                            sdks={this.props.sdks}
                            updateStickerTag={this.updateStickerTag} />
                    )}
                </div>

            </div>
        );
    }

};
