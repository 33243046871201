import React from 'react';
import Pack from './Pack';
import { capitalize } from '../util';
import PageMeta from './PageMeta';

export default class AuthorPage extends React.PureComponent {

    constructor(props) {
        super(props);
        if (props.data) {
            this.state = { ...props.data };
        } else if (window.__DATA__) {
            this.state = window.__DATA__;
            delete window.__DATA__;
        } else {
            this.state = {
                author: {},
                systems: []
            };
        }
    }

    componentDidMount() {
        if (!this.state.loaded) this.fetch();
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.slug !== this.props.slug) {
            this.fetch();
        }
    }

    static loadData(props) {
        const authorsPromise = props.repo.fetchAuthors({ slug: props.slug });   
        const systemsPromise = Promise.all(props.systems.map(system => (
            props.repo.fetchPacks({
                systemId: system.id,
                authorSlug: props.slug
            }).then(packs => ({
                ...system,
                packs
            }))
        )));

        return Promise.all([ authorsPromise, systemsPromise ])
            .then(([ authors, systems ]) => ({
                author: authors[0],
                systems,
                loaded: true
            }));
    }

    fetch() {
        AuthorPage.loadData(this.props)
            .then(data => this.setState(data));
    }

    handleShare() {
        if (!this.state.author) return;

        let authorPart = '';
        if (this.state.author.data.twitterHandle) {
            authorPart = ' @' + this.state.author.data.twitterHandle;
        }
        this.props.share({
            url: `https://stickersearch.net/authors/${this.state.author.slug}`,
            text: `Stickers by ${this.state.author.name}` + authorPart + '.',
            hashtags: [
                'Stickers',
                capitalize(this.state.author.name.replace(/\W/g, ' ')).replace(/ /g, '')
            ],
            isOpen: true
        });
    }

    render() {
        return (
            <div>
                <PageMeta
                    title={`${this.state.author.name} | Sticker Search`}
                    description={`Sticker pack author ${this.state.author.name}. See all sticker packs from this author.`}
                />

                <div className="header-container">
                    <div className="header-content">
                        
                        <h1>
                            <span>{this.state.author.name}</span>{' '}
                            <small>Sticker Author</small>
                        </h1>

                        <div className="social-share-container">
                            <a className="btn btn-primary btn-sm" role="button" target="_blank"
                                    onClick={this.handleShare.bind(this)}>
                                <i className="fa fa-share-alt"></i> Share
                            </a>
                        </div>

                        <blockquote>
                            <p>{this.state.author.desc}</p>
                        </blockquote>

                        <div>
                            <ul className="author-links">
                                {this.state.author.data && this.state.author.data.links.map(link =>
                                    <li key={link.url}>
                                        <a href={link.url} target="_blank">
                                            {link.icon && <span className={`fa fa-${link.icon} fa-lg`}></span>}
                                            {' '}
                                            {link.name}
                                        </a>
                                    </li>
                                )}
                            </ul>
                        </div>

                    </div>
                    <div className="product-container">
                        <div></div>
                    </div>
                </div>

                <div>
                    {this.state.systems.map(system =>
                        <AuthorSystemView slug={this.props.slug} system={system} key={system.id} />
                    )}
                </div>

            </div>
        );
    }

}

class AuthorSystemView extends React.Component {

    render() {
        if (this.props.system.packs.length == 0) return null;

        return (
            <div>
                <PageMeta packsForMetaImage={this.props.system.packs} />
    
                <div className="header-container">
                    <div className="header-content">
                        <h3>
                            <img src={`/images/logos/${this.props.system.slug}-logo.png`} className="system-logo"/> {this.props.system.name} Stickers
                        </h3>
                    </div>
                </div>

                <div className="clearfix">
                    <div>
                        {this.props.system.packs.map(pack =>
                            <Pack pack={pack} key={pack.id} />
                        )}
                    </div>
                </div>

            </div>
        );
    }

}
