import React from 'react';
import $ from 'jquery';

export default class Modal extends React.Component {

    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
        this.showOrHide = this.showOrHide.bind(this);
    }

    componentDidMount() {
        this.showOrHide();
        $(this.modalRef.current).on('hidden.bs.modal', e => {
            this.props.onClose();
        });
        $(this.modalRef.current).on('shown.bs.modal', () => {
            if (this.props.onShow) this.props.onShow();
        });
    }

    componentDidUpdate() {
        this.showOrHide();
    }

    showOrHide() {
        $(this.modalRef.current).modal(this.props.isOpen ? 'show' : 'hide');
    }

    render() {
        return (
            <div className="modal fade bs-example-modal-sm" tabIndex="-1" role="dialog" ref={this.modalRef}>
                <div className={`modal-dialog modal-${this.props.size || 'sm'}`}>
                    <div className="modal-content">

                        <div className="modal-header">

                            <button type="button" className="close" data-dismiss="modal">×</button>
                            <h4 className="modal-title" id="myModalLabel">{this.props.title}</h4>

                        </div>

                        <div className="modal-body">

                            {this.props.children}

                        </div>

                    </div>
                </div>
            </div>
        );
    }

}
